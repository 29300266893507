.clear-button {
  border: 0;
  outline: 0;
  background-color: transparent;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  padding: 0;
  -webkit-tap-highlight-color: transparent; }

.page-404 {
  overflow: hidden;
  height: 100vh;
  margin-top: 0;
  background: #f1f7f2; }

.page__content {
  display: flex;
  align-items: center;
  justify-content: center; }

.typical-message {
  display: flex; }
  .typical-message__img {
    width: 100%; }
  .typical-message__item {
    margin-left: 3.8125rem;
    color: #222;
    font-feature-settings: 'pnum' on, 'lnum' on; }
  .typical-message__title {
    margin-bottom: 0.625rem;
    font-size: 1.5625rem;
    font-weight: bold;
    line-height: 1; }
  .typical-message__text {
    width: 100%;
    max-width: 34.75rem;
    margin-bottom: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.42857; }
  .typical-message .btn {
    width: 11.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }

.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 77.5625rem; }
  .container .container {
    padding-left: 0;
    padding-right: 0; }

.btn {
  border: 0;
  outline: 0;
  background-color: transparent;
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background: radial-gradient(94.23% 574.24% at 69.1% 23.76%, #197B2C 0%, #1E9F37 100%), linear-gradient(0deg, #28853A, #28853A);
  border-radius: 54px;
  padding: 1rem;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-weight: 600;
  line-height: 1.1875;
  overflow: hidden;
  z-index: 0; }
  .btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(94.23% 574.24% at 69.1% 23.76%, #2BA042 0%, #23B840 100%), linear-gradient(0deg, #28853A, #28853A);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s linear; }
  .btn:hover::after {
    opacity: 1; }
  .btn:hover .btn__btn-icon {
    transform: translateX(0.1875rem); }
  .btn_type-secondary {
    background: #ffffff;
    color: #28853A;
    transition: color .3s linear; }
    .btn_type-secondary:hover {
      background: radial-gradient(94.23% 574.24% at 69.1% 23.76%, #197B2C 0%, #1E9F37 100%), linear-gradient(0deg, #28853A, #28853A);
      color: #ffffff; }
  .btn_type-outline {
    border-color: #28853A;
    background: #ffffff;
    color: #28853A;
    transition: color .3s linear; }
    .btn_type-outline:hover {
      color: #ffffff;
      border-color: transparent; }
  .btn_type-outline-secondary {
    border-color: #ffffff;
    background: transparent;
    color: #ffffff;
    transition: color .3s linear; }
    .btn_type-outline-secondary:hover {
      color: #28853A;
      background: #ffffff;
      border-color: transparent; }
    .btn_type-outline-secondary::after {
      content: none; }
  .btn_size-big {
    min-width: 16.5625rem;
    width: 100%; }
  .btn_size-small {
    width: 100%;
    min-width: 8.875rem;
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    padding-top: 1.0625rem;
    padding-bottom: 1rem; }
  .btn_disabled {
    cursor: not-allowed;
    background: #C4C4C4;
    color: #ffffff;
    border-color: transparent; }
    .btn_disabled:hover {
      background: #C4C4C4;
      color: #ffffff; }
      .btn_disabled:hover:hover .btn__btn-icon {
        transform: translateX(0); }
  .btn__btn-icon {
    transition: transform .3s linear; }
  @media screen and (min-width: 36rem) {
    .btn_size-small {
      width: auto;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem; }
    .btn_size-big {
      width: auto; } }
  @media screen and (min-width: 77.5625rem) {
    .container {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (max-width: 992px) {
    .typical-message {
      flex-direction: column; }
      .typical-message__item {
        margin-top: 1.875rem;
        margin-left: 0; } }
